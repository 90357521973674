<template>
 <div id="chart">
  <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
 </div>
</template>

<script>
export default {
 props: ["series", "categories"],
 data() {
  return {
   chartOptions: {
    chart: {
     height: 350,
     type: "line",
     zoom: {
      enabled: false,
     },
    },
    dataLabels: {
     enabled: false,
    },
    stroke: {
     curve: "straight",
    },
    title: {
     text: "",
     align: "left",
    },
    grid: {
     row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
     },
    },
    xaxis: {
     categories: this.categories,
     labels: {
      rotate: -75,
      rotateAlways: true,
      style: {
       colors: [],
       fontSize: "12px",
       fontFamily: "Helvetica, Arial, sans-serif",
       fontWeight: 500,
       cssClass: "apexcharts-xaxis-label",
      },
      /**
       * Allows users to apply a custom formatter function to x-axis labels.
       *
       * @param { String } value - The default value generated
       * @param { Number } timestamp - In a datetime series, this is the raw timestamp
       * @param { object } contains dateFormatter for datetime x-axis
       */
      formatter: function (value, timestamp, opts) {
       if (value && value.length >= 6) {
        let finalValue = "";
        let year = "";
        let month = "";
        let day = "";
        if (value.length > 0) {
         year = value.slice(0, 4);
         month = value.slice(4, 6);
         day = value.slice(6, 8);
        }
        finalValue = day + "/" + month + "/" + year;
        return finalValue;
       } else {
        return value;
       }
      },
     },
    },
    yaxis: {
     labels: {
      formatter: function (val) {
       var minutes = Math.floor(val / 60);
       var seconds = val - minutes * 60;
       return minutes + "(min)" + ":" + seconds + "(s)";
      },
     },
    },
   },
  };
 },
};
</script>

<style></style>
